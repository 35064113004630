// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-login {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-login .social-button {
    margin-bottom: 15px; /* Space between buttons */
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-login .social-button button {
    max-height: 40px;
    width: 100%;
    max-width: 300px;
    padding: 10px 0;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/home/services/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB,EAAE,0BAA0B;IAC/C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".social-login {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.social-login .social-button {\n    margin-bottom: 15px; /* Space between buttons */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.social-login .social-button button {\n    max-height: 40px;\n    width: 100%;\n    max-width: 300px;\n    padding: 10px 0;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
