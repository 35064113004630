// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark-theme .user-profile {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #333333;
}

.dark-theme .user-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid #4CAF50;
}

.dark-theme .user-name {
    margin-right: 15px;
    font-weight: bold;
    color: #ffffff;
}

.dark-theme .logout-btn {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.dark-theme .logout-btn:hover {
    background-color: #c0392b;
}

.dark-theme .login-link {
    color: #4CAF50;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.dark-theme .login-link:hover {
    color: #81c784;
}

.dark-theme .a {
    color: #4CAF50;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
}

.dark-theme .a:hover {
    color: #81c784;
}
`, "",{"version":3,"sources":["webpack://./src/component/user/UserProfileDark.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".dark-theme .user-profile {\n    display: flex;\n    align-items: center;\n    padding: 10px 20px;\n    border-radius: 10px;\n    background-color: #333333;\n}\n\n.dark-theme .user-avatar {\n    width: 30px;\n    height: 30px;\n    border-radius: 50%;\n    margin-right: 15px;\n    border: 2px solid #4CAF50;\n}\n\n.dark-theme .user-name {\n    margin-right: 15px;\n    font-weight: bold;\n    color: #ffffff;\n}\n\n.dark-theme .logout-btn {\n    background-color: #e74c3c;\n    color: white;\n    border: none;\n    padding: 6px 12px;\n    cursor: pointer;\n    border-radius: 5px;\n    font-size: 0.9rem;\n    transition: background-color 0.3s ease;\n}\n\n.dark-theme .logout-btn:hover {\n    background-color: #c0392b;\n}\n\n.dark-theme .login-link {\n    color: #4CAF50;\n    text-decoration: none;\n    font-weight: bold;\n    transition: color 0.3s ease;\n}\n\n.dark-theme .login-link:hover {\n    color: #81c784;\n}\n\n.dark-theme .a {\n    color: #4CAF50;\n    text-decoration: none;\n    font-weight: bold;\n    transition: color 0.3s ease;\n}\n\n.dark-theme .a:hover {\n    color: #81c784;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
