import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/pied-piper.png";
import mp3 from "../../assets/audio/stories/pied-piper/pied-piper.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Legend",
    title:
      "Hello, I’m <span>The Pied Piper of Hamelin</span>. Listen to my Enigmatic Tune.",
    description:
      "Discover the tale of the Pied Piper of Hamelin, a mysterious figure who uses his magical pipe to lead away the town's troubles.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--pied-piper",
  },
];

const piedPiperTabsData = [
  {
    title: "Story",
    content: `
      <p>The Pied Piper is a folk tale about a piper dressed in multicolored clothing who is hired by the town of Hamelin to lure away its rats with his magic pipe. When the town refuses to pay for his services, the piper retaliates by using his pipe to lead the children away from the town, never to be seen again.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The story of the Pied Piper is based on a legend from the town of Hamelin in Lower Saxony, Germany. </p>
        <p> - The tale has been adapted into various literary and artistic forms, including poems by Robert Browning and Johann Wolfgang von Goethe. </p>
        <p> - The Pied Piper's colorful clothing has made him a memorable character in folklore. </p>
        <p> - The story is often interpreted as a cautionary tale about the consequences of breaking promises. </p>
        <p> - Historical records suggest that the legend may be based on real events that occurred in Hamelin in the 13th century. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The legend of the Pied Piper of Hamelin dates back to the Middle Ages, with the earliest references appearing in town chronicles around the 13th century. Various theories suggest that the tale may be based on a historical event involving the loss or migration of the town's children. Over time, the story has evolved and been embellished, becoming a popular folk tale throughout Europe.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>The story of the Pied Piper has been retold by various authors over the centuries. One of the most famous literary adaptations is a poem by Robert Browning (1812-1889), published in 1842. Browning was an English poet and playwright known for his dramatic monologues and vivid storytelling. His version of the Pied Piper has helped to popularize the legend in modern times.</p>
    `,
  },
];

const PiedPiper = () => (
  <Fairytale
    pageTitle="The Pied Piper of Hamelin"
    logo="logo-light"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="The Pied Piper of Hamelin, a mysterious figure with a magical pipe, helps the town of Hamelin by leading away its troubles. His story is a cautionary tale of promises and consequences."
    audioSrc={mp3}
    tabsData={piedPiperTabsData}
  />
);

export default PiedPiper;
