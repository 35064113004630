import React, { Component } from "react";
import { Link } from "react-router-dom";
const PortfolioList = [
  {
    image: "cinderella",
    category: "Enchanted Kingdoms",
    title: "Cinderella",
  },
  {
    image: "snow-white",
    category: "Princesses and Princes",
    title: "Snow White",
  },
  {
    image: "hansel-gretel",
    category: "Folklore Legends",
    title: "Hansel and Gretel",
  },
  {
    image: "aladdin",
    category: "Stories of the World",
    title: "Aladdin",
  },
  {
    image: "little-mermaid",
    category: "Modern Fables",
    title: "The Little Mermaid",
  },
  {
    image: "sleeping-beauty",
    category: "Enchanted Kingdoms",
    title: "Sleeping Beauty",
  },
  {
    image: "frog-prince",
    category: "Mystical Realms",
    title: "The Frog Prince",
  },
  {
    image: "pied-piper",
    category: "Urban Legends",
    title: "The Pied Piper of Hamelin",
  },
];

// function to lowercase and replace space with hyphen for the title
function formatTitle(title) {
  return title.toLowerCase().replace(/\s+/g, "-");
}

class PortfolioMasonry extends Component {
  render() {
    const { column } = this.props;
    const list = PortfolioList.slice(0, this.props.item);
    return (
      <React.Fragment>
        {list.map((value, index) => {
          const url = `/stories/${formatTitle(value.title)}`;
          return (
            <div className={`${column}`} key={index}>
              <div className="Tilt-inner">
                <div className="portfolio">
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4>
                        <a href={url}>{value.title}</a>
                      </h4>
                      <div className="portfolio-button">
                        <a className="rn-btn" href={url}>
                          View Details
                        </a>
                      </div>
                    </div>
                  </div>
                  <Link className="link-overlay" to={url}></Link>
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default PortfolioMasonry;
