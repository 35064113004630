import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/aladdin.png";
import mp3 from "../../assets/audio/stories/aladdin/aladdin.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Hero",
    title: "Hello, I’m <span>Aladdin</span>. Discover my Magical Journey.",
    description:
      "Experience the magical journey of Aladdin, a young man who finds a powerful genie and transforms his destiny.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--aladdin",
  },
];

const aladdinTabsData = [
  {
    title: "Story",
    content: `
      <p>Aladdin is a Middle Eastern folk tale about a young man who discovers a magical lamp containing a genie that grants him three wishes. With the help of the genie, Aladdin gains wealth and marries the princess. However, a wicked sorcerer plots to steal the lamp and overthrow Aladdin. Through courage and wit, Aladdin defeats the sorcerer and secures his happy future.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The story of Aladdin is part of the collection known as "One Thousand and One Nights" or "Arabian Nights." </p>
        <p> - The tale was added to the collection by French translator Antoine Galland in the 18th century. </p>
        <p> - Aladdin's story has been adapted into numerous films, including Disney's animated feature in 1992. </p>
        <p> - The phrase "Open Sesame" is often associated with Aladdin, though it originates from the tale of "Ali Baba and the Forty Thieves" in the same collection. </p>
        <p> - The character of the genie in the lamp has become a popular cultural icon. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>Aladdin's story originated as part of the "One Thousand and One Nights" collection, which includes a variety of Middle Eastern and South Asian folk tales. Although Aladdin was not part of the original Arabic manuscripts, it was added by French translator Antoine Galland in the early 18th century after he heard it from a Syrian storyteller. The tale has since become one of the most well-known stories from the collection.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>The version of Aladdin most people are familiar with comes from the "One Thousand and One Nights," a collection of Middle Eastern folk tales compiled during the Islamic Golden Age. The story was popularized in Europe by French translator Antoine Galland (1646-1715), who included it in his translation of the collection after hearing it from a Syrian storyteller named Hanna Diyab.</p>
    `,
  },
];

const Aladdin = () => (
  <Fairytale
    pageTitle="Aladdin"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="Aladdin, a young man from humble beginnings, discovers a magical lamp that changes his life. With the help of a powerful genie, he embarks on a journey of adventure, love, and self-discovery."
    audioSrc={mp3}
    tabsData={aladdinTabsData}
  />
);

export default Aladdin;
