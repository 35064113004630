import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import PageScrollTop from "./component/PageScrollTop";
import * as serviceWorker from "./serviceWorker";
import { Provider, useSelector } from "react-redux"; // Import Provider
import store from "./store"; // Import the Redux store

import "./index.scss";

// Import components
import HomePortfolio from "./home/HomePortfolio";
import Cinderella from "./home/fairytales/Cinderella";
import SnowWhite from "./home/fairytales/SnowWhite";
import HanselAndGretel from "./home/fairytales/HanselAndGretel";
import Aladdin from "./home/fairytales/Aladdin";
import LittleMermaid from "./home/fairytales/LittleMermaid";
import SleepingBeauty from "./home/fairytales/SleepingBeauty";
import FrogPrince from "./home/fairytales/FrogPrince";
import PiedPiper from "./home/fairytales/PiedPiper";
import Login from "./home/services/Login";
import Register from "./home/services/Register";
import ForgotPassword from "./home/services/ForgotPassword";
import Error404 from "./elements/error404"; // Note: The component name should be capitalized

// Import react-ga4
import ReactGA from "react-ga4";
import ErrorBoundary from "./component/common/ErrorBoundary";
import EmailVerification from "./home/services/EmailVerification";
import ResetPassword from "./home/services/ResetPassword";
import { HelmetProvider } from "react-helmet-async";

const AuthRoute = ({ children }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    // Redirect authenticated users away from login, register, and forgot password pages
    return <Navigate to="/" />;
  }

  // Render the requested route if the user is not authenticated
  return children;
};

// Initialize Google Analytics with your GA4 ID
ReactGA.initialize("G-94MDSRBVC8");

const routes = [
  {
    path: `${process.env.PUBLIC_URL}/`,
    element: <HomePortfolio />,
    exact: true,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/cinderella`,
    element: <Cinderella />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/snow-white`,
    element: <SnowWhite />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/hansel-and-gretel`,
    element: <HanselAndGretel />,
  },
  { path: `${process.env.PUBLIC_URL}/stories/aladdin`, element: <Aladdin /> },
  {
    path: `${process.env.PUBLIC_URL}/stories/the-little-mermaid`,
    element: <LittleMermaid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/sleeping-beauty`,
    element: <SleepingBeauty />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/the-frog-prince`,
    element: <FrogPrince />,
  },
  {
    path: `${process.env.PUBLIC_URL}/stories/the-pied-piper-of-hamelin`,
    element: <PiedPiper />,
  },

  { path: `${process.env.PUBLIC_URL}/404`, element: <Error404 /> },
  {
    path: `${process.env.PUBLIC_URL}/verify-email`,
    element: <EmailVerification />,
  },
];

// Create a wrapper component to handle pageview tracking
const PageViewTracker = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    // Track the initial page load
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    // Listen for route changes and track page views
    // `useLocation` automatically listens for location changes
  }, [location]);

  return children;
};

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <PageViewTracker>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  element={route.element}
                />
              ))}

              {/* Protect these routes so they are only accessible to non-authenticated users */}
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                element={
                  <AuthRoute>
                    <Login />
                  </AuthRoute>
                }
              />
              <Route
                path={`${process.env.PUBLIC_URL}/register`}
                element={
                  <AuthRoute>
                    <Register />
                  </AuthRoute>
                }
              />
              <Route
                path={`${process.env.PUBLIC_URL}/forgot-password`}
                element={
                  <AuthRoute>
                    <ForgotPassword />
                  </AuthRoute>
                }
              />
              <Route
                path={`${process.env.PUBLIC_URL}/reset-password`}
                element={
                  <AuthRoute>
                    <ResetPassword />
                  </AuthRoute>
                }
              />

              <Route path="*" element={<Error404 />} />
            </Routes>
          </PageViewTracker>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      {" "}
      <ErrorBoundary>
        <Provider store={store}>
          <Root />
        </Provider>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>,
);
serviceWorker.register();
