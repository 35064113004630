import React, { Component, Fragment } from "react";
import { FiLogIn, FiChevronUp } from "react-icons/fi";
import { connect } from "react-redux";
import { login } from "../../features/auth/authActions";
import Helmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import ScrollToTop from "react-scroll-up";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

import "./Login.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;

    try {
      await this.props.login({ email, password });
      this.props.history.push("/");
    } catch (error) {
      this.setState({ error: "Invalid credentials. Please try again." });
    }
  };

  handleGoogleResponse = async (response) => {
    try {
      const decodedToken = jwtDecode(response.credential);
      const googleEmail = decodedToken.email;

      // Assuming your backend can handle login via Google, use the token or email to authenticate
      await this.props.login({
        email: googleEmail,
        googleToken: response.credential,
      });

      this.props.history.push("/");
    } catch (error) {
      this.setState({ error: "Google login failed. Please try again." });
    }
  };

  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Login" />
        <Header />

        <div
          className="login-area d-flex justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="login-form-wrapper text-center p-5 bg-white shadow-sm rounded">
                  <h2 className="title mb-4">Login</h2>
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  )}
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-box mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-box mb-4">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="login-btn">
                      <button
                        className="rn-button-style--2 btn-solid w-100"
                        type="submit"
                      >
                        <FiLogIn /> Login
                      </button>
                    </div>
                  </form>

                  <div className="social-login mt-4">
                    <div className="social-button">
                      <GoogleOAuthProvider clientId="489274313573-ok9ht7siaajv1u46ntest439k96vepdg.apps.googleusercontent.com">
                        <GoogleLogin
                          onSuccess={this.handleGoogleResponse}
                          onError={() =>
                            this.setState({
                              error: "Google login failed. Please try again.",
                            })
                          }
                          theme="outline"
                          size="large"
                        />
                      </GoogleOAuthProvider>
                    </div>
                  </div>
                  <p className="mt--20">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Don't have an account? <a href="/register">Register</a>
                  </p>
                  <p>
                    Forgot your password?{" "}
                    <a href="/forgot-password">Forgot Password</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo />

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
