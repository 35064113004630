import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../features/auth/authActions";
import classNames from "classnames"; // Optional, but helpful for conditional classes

import "./UserProfileLight.css"; // Base light CSS
import "./UserProfileDark.css"; // Base dark CSS
import "./UserProfile.css";

const UserProfile = ({ theme = "light" }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div
      className={classNames("user-profile", {
        "dark-theme": theme === "dark",
        "light-theme": theme === "light",
      })}
    >
      {isAuthenticated ? (
        <div className="logged-in">
          <img
            src={user.profilePicture}
            alt={user.name}
            className="user-avatar"
          />
          <span className="user-name">{user.name}</span>
          <button onClick={handleLogout} className="logout-btn">
            Logout
          </button>
        </div>
      ) : (
        <div className="logged-out">
          <Link to="/login" className="login-link">
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserProfile;
