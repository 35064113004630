// src/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Retrieve the user and token from localStorage if they exist
const storedToken = localStorage.getItem("token");
const storedUser = localStorage.getItem("user");

const initialState = {
  isAuthenticated: !!storedToken, // true if a token is found
  user: storedUser ? JSON.parse(storedUser) : null,
  token: storedToken || null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.error = null;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.error = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload.error;
    },
  },
});

export const { loginSuccess, logoutSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;
