import axios from "axios";
import { loginSuccess, loginFailure, logoutSuccess } from "./authSlice";

export const login = (credentials) => async (dispatch) => {
  try {
    let response;

    if (credentials.googleToken) {
      // If Google token is present, handle Google SSO login
      console.log("Google token:", credentials.googleToken);
      response = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/api/auth/google-login`,
        { token: credentials.googleToken },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    } else {
      // Handle regular email/password login
      response = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/api/auth/login`,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
    }

    const { token, user } = response.data;

    // Save token and user data in localStorage
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));

    // Add profile picture URL to the user object
    user.profilePicture = `https://eu.ui-avatars.com/api/?name=${encodeURIComponent(
      user.full_name,
    )}&size=250`;
    user.name = user.full_name;

    // Dispatch login success with user data and token
    dispatch(loginSuccess({ user, token }));
  } catch (error) {
    const errorMessage = error.response?.data?.error || "Login failed";
    console.error("Login error:", errorMessage); // Log the error for debugging

    // Dispatch login failure
    dispatch(loginFailure(errorMessage));
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  dispatch(logoutSuccess());
};
