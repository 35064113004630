import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/snow-white.png";
import mp3 from "../../assets/audio/stories/snow-white/snow-white.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Princess",
    title:
      "Hello, I’m <span>Snow White</span>. Welcome to my Enchanted Forest.",
    description:
      "Discover the tale of Snow White, a princess who escapes her wicked stepmother's jealousy to find friendship and love.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--snow-white",
  },
];

const snowWhiteTabsData = [
  {
    title: "Story",
    content: `
      <p>Snow White is a classic fairy tale about a beautiful young princess whose wicked stepmother, jealous of her beauty, orders her to be killed. However, Snow White escapes and finds refuge with seven dwarfs in their cottage in the forest. The evil queen discovers Snow White's survival and tries to kill her using a poisoned apple, but Snow White is ultimately saved by a prince who awakens her with a kiss.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - Snow White was the first full-length animated feature film produced by Walt Disney in 1937. </p>
        <p> - The story of Snow White has been adapted into numerous films, plays, and books. </p>
        <p> - The phrase "Mirror, mirror, on the wall" is one of the most famous lines from the story. </p>
        <p> - Snow White is known for her characteristic black hair, fair skin, and red lips. </p>
        <p> - The Grimm Brothers' version of Snow White, published in 1812, is the most well-known adaptation. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The tale of Snow White can be traced back to various European folk tales. The most famous version is from the Brothers Grimm, published in their collection in 1812. The story has evolved over time, with various adaptations and interpretations in literature, theater, and film, solidifying its place as one of the most beloved fairy tales of all time.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>The Brothers Grimm, Jacob (1785-1863) and Wilhelm (1786-1859), were German academics, linguists, and cultural researchers who collected and published folklore. Their most famous works include "Snow White," "Cinderella," "Hansel and Gretel," and "Rapunzel." Their collections have had a significant impact on popular culture and continue to be widely read and adapted.</p>
    `,
  },
];

const SnowWhite = () => (
  <Fairytale
    pageTitle="Snow White"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="Snow White, known for her beauty and kindness, finds refuge with seven dwarfs when she is pursued by her jealous stepmother. Despite facing numerous challenges, her gentle nature and purity of heart ultimately lead to her happy ending."
    audioSrc={mp3}
    tabsData={snowWhiteTabsData}
  />
);

export default SnowWhite;
