import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFairyTales } from "../api/fairyTalesApi"

const FairyTalesIndex = () => {
  const [fairyTales, setFairyTales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the fairy tales when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tales = await getFairyTales();
        setFairyTales(tales);
      } catch (err) {
        setError("Failed to fetch fairy tales. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="fairy-tales-index">
      <h1>Available Fairy Tales</h1>
      <ul>
        {fairyTales.length > 0 &&
          fairyTales.map((tale) => (
            tale && tale.attributes && (
              <li key={tale.id}>
                <Link to={`/stories/${tale.attributes.slug}`}>
                  {tale.attributes.title || "Untitled Tale"}
                </Link>
              </li>
            )
          ))}
      </ul>
    </div>
  );
};

export default FairyTalesIndex;

