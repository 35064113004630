// api/fairyTalesApi.js
import axios from 'axios';

// Define the Strapi API base URL
const STRAPI_API_URL = 'https://bo.naptimestories.com/api';
const STRAPI_API_TOKEN = 'e39b2488ea6f2e7f0bcb3cdb15d8aa45d85aee37a5b1b10472f032980e81252c0811a474f552fa57576b95c0f3112e91eb3721da9c276fd2354173fd3e0577e81b24df240b85c4f5b53a1e3186383e8d192dd8c29eba9563cc2656b6a0248a66fb1634bf49c080306c16e58bf4628404da287f95771f1890845946361d507f6a'

const headers = {
  Authorization: `Bearer ${STRAPI_API_TOKEN}`,
  'Content-Type': 'application/json',
};

// Fetch all fairy tales
export const getFairyTales = async () => {
  try {
    const response = await axios.get(`${STRAPI_API_URL}/fairy-tales`, { headers });
    return response.data.data;  // Return the list of fairy tales
  } catch (error) {
    console.error('Error fetching fairy tales:', error);
    return [];
  }
};

// Fetch a single fairy tale by ID or slug
export const getFairyTaleById = async (id) => {
  try {
    const response = await axios.get(`${STRAPI_API_URL}/fairy-tales/${id}`, { headers });
    return response.data.data;  // Return the specific fairy tale
  } catch (error) {
    console.error('Error fetching fairy tale:', error);
    return null;
  }
};

