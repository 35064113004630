import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/little-mermaid.png";
import mp3 from "../../assets/audio/stories/little-mermaid/little-mermaid.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Princess",
    title:
      "Hello, I’m <span>The Little Mermaid</span>. Dive into my Ocean World.",
    description:
      "Explore the underwater world of the Little Mermaid, a princess who dreams of living on land and finding true love.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--little-mermaid",
  },
];

const littleMermaidTabsData = [
  {
    title: "Story",
    content: `
      <p>The Little Mermaid is a fairy tale about a young mermaid princess who dreams of becoming human after falling in love with a human prince. She makes a deal with a sea witch, trading her voice for human legs. Despite enduring great hardships and ultimately not winning the prince's love, the mermaid's spirit is transformed into an ethereal being, gaining immortality.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - "The Little Mermaid" was written by Danish author Hans Christian Andersen and published in 1837. </p>
        <p> - The story has been adapted into numerous films, including Disney's popular animated version in 1989. </p>
        <p> - Andersen's original tale is much darker and more tragic than many of its adaptations. </p>
        <p> - The Little Mermaid statue in Copenhagen, Denmark, is a famous landmark based on the fairy tale. </p>
        <p> - The story explores themes of love, sacrifice, and the longing for a different existence. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>"The Little Mermaid" was first published in 1837 by Hans Christian Andersen in Denmark. The story has its roots in European folklore but is an original work by Andersen. It reflects his themes of unrequited love and the quest for an immortal soul, and it has been interpreted in various ways through different cultural adaptations over the years.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>Hans Christian Andersen (1805-1875) was a Danish author best known for his fairy tales, including "The Little Mermaid," "The Ugly Duckling," "The Snow Queen," and "The Emperor's New Clothes." His stories have been translated into numerous languages and continue to be beloved around the world for their imaginative and often poignant narratives.</p>
    `,
  },
];

const LittleMermaid = () => (
  <Fairytale
    pageTitle="The Little Mermaid"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="The Little Mermaid, a princess of the sea, dreams of life on land. She sacrifices her voice to gain legs, embarking on a journey to win the heart of a human prince and find true love."
    audioSrc={mp3}
    tabsData={littleMermaidTabsData}
  />
);

export default LittleMermaid;
