import React, { Component, Fragment } from "react";
import { FiMail, FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import ScrollToTop from "react-scroll-up";
import axios from "axios"; // Import axios for making HTTP requests

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      successMessage: null,
      errorMessage: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email } = this.state;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/api/auth/forgot-password`,
        { email },
      );
      if (response.status === 200) {
        this.setState({
          successMessage: "A reset link has been sent to your email address.",
          errorMessage: null,
        });
      }
    } catch (error) {
      this.setState({
        successMessage: null,
        errorMessage: "An error occurred. Please try again.",
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Forgot Password" />
        <Header />

        {/* Start Forgot Password Area */}
        <div
          className="forgot-password-area d-flex justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="forgot-password-form-wrapper text-center p-5 bg-white shadow-sm rounded">
                  <h2 className="title mb-4">Forgot Password</h2>
                  <p className="mb-4">
                    Enter your email address and we'll send you a link to reset
                    your password.
                  </p>
                  {this.state.successMessage && (
                    <div className="alert alert-success" role="alert">
                      {this.state.successMessage}
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.errorMessage}
                    </div>
                  )}
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-box mb-4">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email Address"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="forgot-password-btn">
                      <button
                        className="rn-button-style--2 btn-solid w-100"
                        type="submit"
                      >
                        <FiMail /> Send Reset Link
                      </button>
                    </div>
                  </form>
                  <p className="mt--20">
                    Remember your password? <a href="/login">Login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Forgot Password Area */}

        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default ForgotPassword;
