import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/cinderella.png";
import mp3 from "../../assets/audio/stories/cinderella/cinderella-alice-v1.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Princess",
    title: "Hello, I’m <span>Cinderella</span>. Welcome to my Magical World.",
    description:
      "Experience the enchanting story of Cinderella, a young woman whose kindness and courage help her overcome adversity and find her happily ever after.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--cinderella",
  },
];

const tabsData = [
  {
    title: "Story",
    content: `
      <p>Cinderella is a classic fairy tale about a young woman living in unfortunate circumstances that are suddenly changed to remarkable fortune. The tale revolves around Cinderella, her wicked stepmother, and stepsisters, and her magical transformation by a fairy godmother to attend the royal ball, where she captures the heart of the prince.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The story of Cinderella is one of the oldest and most popular fairy tales in the world. </p>
        <p> - The tale has been adapted into numerous films, ballets, and operas. </p>
        <p> - The glass slipper is one of the most iconic symbols of the Cinderella story. </p>
        <p> - The story has been retold in various cultures and languages, with different variations and endings. </p>
        <p> - The first known version of the story dates back to ancient Greece the tale of Rhodopis, a Greek slave who marries the king of Egypt. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The story of Cinderella can be traced back to antiquity and is part of the folklore of many cultures. The earliest known version is the ancient Greek tale of Rhodopis, a Greek slave girl who marries the king of Egypt. The most famous version, however, was penned by French author Charles Perrault in 1697, titled "Cendrillon ou la petite pantoufle de verre."</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>Charles Perrault (1628-1703) was a French author and member of the Académie Française. He laid the foundations for a new literary genre, the fairy tale, with his works derived from earlier folk tales. His notable works include "Cinderella," "Sleeping Beauty," and "Little Red Riding Hood." Perrault's tales have had a lasting impact on the world of literature and storytelling.</p>
    `,
  },
];

const Cinderella = () => (
  <Fairytale
    pageTitle="Personal Portfolio"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="Cinderella is a young woman who, despite being mistreated by her stepmother and stepsisters, remains kind and hopeful. With the help of her Fairy Godmother, she transforms for one magical night, meeting the Prince at a royal ball. Her true identity is revealed by a lost glass slipper, leading to her happily ever after."
    audioSrc={mp3}
    tabsData={tabsData}
  />
);

export default Cinderella;
