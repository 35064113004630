import React, { Component, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import HeaderTwo from "../../component/header/HeaderTwo";
import FooterTwo from "../../component/footer/FooterTwo";
import TabTwo from "../../elements/tab/TabTwo";
import Helmet from "../../component/common/Helmet";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const Player = React.forwardRef((props, ref) => (
  <div>
    <AudioPlayer
      ref={ref}
      src={props.audioSrc}
      onPlay={(e) => console.log("onPlay")}
      // other props here
    />
  </div>
));

class Fairytale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPlayer: false,
    };
    this.audioPlayerRef = React.createRef();
  }

  handleButtonClick = () => {
    this.setState({ showPlayer: true }, () => {
      if (this.audioPlayerRef.current) {
        this.audioPlayerRef.current.audio.current.play();
      }
    });
  };

  render() {
    const {
      pageTitle,
      logo,
      sliderData,
      aboutImage,
      aboutTitle,
      aboutDescription,
      audioSrc,
      tabsData,
    } = this.props;

    return (
      <Fragment>
        <Helmet pageTitle={pageTitle} />

        <HeaderTwo logo={logo} color="color-black" />

        {/* Start Slider Area */}
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {sliderData.map((value, index) => (
            <div
              className={`slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image ${value.bgImageClass}`}
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: value.title }}
                        ></h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--3 btn-primary-color"
                            onClick={this.handleButtonClick}
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {this.state.showPlayer && (
                  <Player ref={this.audioPlayerRef} audioSrc={audioSrc} />
                )}
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* End Slider Area */}

        {/* Start About Area */}
        <div className="about-area about-position-top pb--120 bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src={aboutImage}
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner pt--100">
                    <div className="section-title">
                      <h2 className="title">{aboutTitle}</h2>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{ __html: aboutDescription }}
                      ></p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" tabsData={tabsData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area */}

        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Fairytale;
