import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async"; // Update to react-helmet-async
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState("Verifying...");
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Extract the token from the URL query parameter
        const query = new URLSearchParams(location.search);
        const token = query.get("token");

        if (!token) {
          setStatus("Invalid verification link.");
          return;
        }

        // Call the /verify-email endpoint with the token
        const response = await axios.get(
          `${process.env.REACT_APP_AUTH_API_URL}/api/auth/verify-email`,
          {
            params: { token },
            headers: { "Content-Type": "application/json" },
          },
        );

        if (response.status === 200) {
          const data = response.data;

          if (data.status === "success") {
            setStatus(
              "Your email has been successfully verified! Redirecting...",
            );
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 2500);
          } else if (data.status === "already_verified") {
            setStatus("Your email has already been verified. Redirecting...");
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, 2500);
          } else if (data.status === "error") {
            setStatus("Invalid or expired token. Please try again.");
          }
        } else {
          setStatus("Failed to verify email. Please try again.");
        }
      } catch (error) {
        console.error("Verification error:", error);
        if (error.response && error.response.status === 400) {
          setStatus(
            "Verification failed. The link may have expired or is invalid.",
          );
        } else {
          setStatus(
            "Failed to verify email due to a server error. Please try again later.",
          );
        }
        setError(error.message);
      } finally {
        // Clear the token and navigate to the login page
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 2500);
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Email Verification</title>
      </Helmet>
      <Header />

      {/* Start Verification Area */}
      <div
        className="verification-area d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="verification-form-wrapper text-center p-5 bg-white shadow-sm rounded">
                <h2 className="title mb-4">Email Verification</h2>
                <p>{status}</p>
                {error && (
                  <div className="error-message mt-3 text-danger">
                    <p>Details: {error}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Verification Area */}

      <FooterTwo />
    </React.Fragment>
  );
};

export default EmailVerification;
