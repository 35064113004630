import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/hansel-gretel.png";
import mp3 from "../../assets/audio/stories/hansel-gretel/hansel-gretel.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Siblings",
    title:
      "Hello, We’re <span>Hansel and Gretel</span>. Join us on our Adventure.",
    description:
      "Follow the thrilling adventure of Hansel and Gretel, siblings who outsmart a wicked witch in a candy-covered house.",
    buttonText: "Hear Our Story",
    buttonLink: "#",
    bgImageClass: "bg_image--hansel-gretel",
  },
];

const hanselAndGretelTabsData = [
  {
    title: "Story",
    content: `
      <p>Hansel and Gretel is a famous fairy tale about a young brother and sister who are abandoned in a forest by their parents due to a famine. The children come across a house made of candy and gingerbread, owned by a wicked witch who lures them inside with the intention of eating them. Using their wits, Hansel and Gretel manage to outsmart the witch and escape, eventually finding their way back home with treasures from the witch's house.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The story of Hansel and Gretel is one of the most famous fairy tales collected by the Brothers Grimm. </p>
        <p> - The candy house is one of the most iconic elements of the story, often depicted in various adaptations. </p>
        <p> - The tale highlights themes of resourcefulness and bravery. </p>
        <p> - Hansel and Gretel has been adapted into operas, films, and ballets. </p>
        <p> - The story was first published by the Brothers Grimm in 1812 in their collection of folk tales. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The story of Hansel and Gretel can be traced back to the European tradition of folk tales. The most well-known version was published by the Brothers Grimm in 1812. The tale reflects the harsh realities of the medieval period, including famine and abandonment. Over time, it has been adapted into various cultural forms, cementing its place as a classic fairy tale.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>The Brothers Grimm, Jacob (1785-1863) and Wilhelm (1786-1859), were German academics, linguists, and cultural researchers who collected and published folklore. Their most famous works include "Hansel and Gretel," "Snow White," "Cinderella," and "Rapunzel." Their collections have had a significant impact on popular culture and continue to be widely read and adapted.</p>
    `,
  },
];

const HanselAndGretel = () => (
  <Fairytale
    pageTitle="Hansel and Gretel"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="Hansel and Gretel, siblings abandoned in the forest, find a house made of candy. They must use their wit and bravery to escape the clutches of a wicked witch and find their way back home."
    audioSrc={mp3}
    tabsData={hanselAndGretelTabsData}
  />
);

export default HanselAndGretel;
