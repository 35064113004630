import React, { Component, Fragment } from "react";
import { FiUserPlus, FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import ScrollToTop from "react-scroll-up";
import axios from "axios"; // Import Axios for HTTP requests

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      error: null, // To capture any error messages
      success: null, // To capture success messages
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, email, password, confirmPassword } = this.state;

    // Check if passwords match
    if (password !== confirmPassword) {
      this.setState({ error: "Passwords do not match" });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/api/auth/register`,
        {
          email: email,
          password: password,
          full_name: name,
        },
      );

      if (response.status === 201) {
        this.setState({
          success:
            "Registration successful. Please check your email for verification.",
          error: null,
        });
      }
    } catch (error) {
      this.setState({
        error:
          error.response?.data?.message ||
          "Registration failed. Please try again.",
        success: null,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Register" />
        <Header />

        {/* Start Register Area */}
        <div
          className="register-area d-flex justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="register-form-wrapper text-center p-5 bg-white shadow-sm rounded">
                  <h2 className="title mb-4">Register</h2>
                  {this.state.error && (
                    <p className="text-danger">{this.state.error}</p>
                  )}
                  {this.state.success && (
                    <p className="text-success">{this.state.success}</p>
                  )}
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-box mb-3">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Full Name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-box mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-box mb-3">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-box mb-4">
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="register-btn">
                      <button
                        className="rn-button-style--2 btn-solid w-100"
                        type="submit"
                      >
                        <FiUserPlus /> Register
                      </button>
                    </div>
                  </form>
                  <p className="mt--20">
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Register Area */}

        <FooterTwo />

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Register;
