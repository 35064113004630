import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    const { tabsData, tabStyle } = this.props;

    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    {tabsData.map((tab, index) => (
                      <Tab key={index}>{tab.title}</Tab>
                    ))}
                  </TabList>

                  {tabsData.map((tab, index) => (
                    <TabPanel key={index}>
                      <div className="single-tab-content">
                        <h3>{tab.title}</h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: tab.content }}
                        />
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
