import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/sleeping-beauty.png";
import mp3 from "../../assets/audio/stories/sleeping-beauty/sleeping-beauty.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Princess",
    title: "Hello, I’m <span>Sleeping Beauty</span>. Enter my Dreamy Realm.",
    description:
      "Learn the story of Sleeping Beauty, a princess cursed to sleep for a hundred years until awakened by true love's kiss.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--sleeping-beauty",
  },
];

const sleepingBeautyTabsData = [
  {
    title: "Story",
    content: `
      <p>Sleeping Beauty is a classic fairy tale about a princess who is cursed by an evil fairy to die on her sixteenth birthday by pricking her finger on a spindle. However, a good fairy alters the curse so that the princess will fall into a deep sleep instead of dying, only to be awakened by a prince's kiss. After many years, a brave prince finds the sleeping princess and awakens her with a kiss, breaking the curse and leading to their happily ever after.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The story of Sleeping Beauty has been told and retold in various forms for centuries, with notable versions by Charles Perrault and the Brothers Grimm. </p>
        <p> - Disney's animated film "Sleeping Beauty" was released in 1959 and is one of the most famous adaptations of the tale. </p>
        <p> - The original story includes various elements such as fairies, a spinning wheel, and a thorn-covered castle. </p>
        <p> - The tale explores themes of fate, love, and the battle between good and evil. </p>
        <p> - Tchaikovsky's ballet "The Sleeping Beauty," first performed in 1890, is a renowned musical adaptation of the story. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The story of Sleeping Beauty has roots in European folklore and has been adapted by many authors over the centuries. One of the earliest known versions is "Sun, Moon, and Talia" by Italian author Giambattista Basile, published in 1634. Charles Perrault's "La Belle au bois dormant" (The Sleeping Beauty in the Wood), published in 1697, is one of the most well-known versions. The Brothers Grimm also included a version of the tale, titled "Dornröschen" (Little Briar Rose), in their collection of fairy tales.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>Charles Perrault (1628-1703) was a French author known for laying the foundations of the fairy tale genre with his works, including "Sleeping Beauty," "Cinderella," and "Little Red Riding Hood." His version of Sleeping Beauty, "La Belle au bois dormant," published in 1697, has become one of the most famous retellings of the story. The Brothers Grimm, Jacob (1785-1863) and Wilhelm (1786-1859), also popularized the tale with their version "Dornröschen," which was published in their collection of fairy tales in the early 19th century.</p>
    `,
  },
];

const SleepingBeauty = () => (
  <Fairytale
    pageTitle="Sleeping Beauty"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="Sleeping Beauty, a princess cursed by an evil fairy, falls into a deep sleep for a hundred years. Her destiny changes when a brave prince awakens her with a kiss, bringing peace and joy back to the kingdom."
    audioSrc={mp3}
    tabsData={sleepingBeautyTabsData}
  />
);

export default SleepingBeauty;
