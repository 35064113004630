import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Naptime Stories</title>
          <meta
            name="description"
            content="Naptime Stories - Let your child fall asleep to classical stories told in your voice"
          />
        </Helmet>
      </React.Fragment>
    );
  }
}

export default PageHelmet;
