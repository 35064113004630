import React, { Component, Fragment } from "react";
import { FiLock, FiChevronUp } from "react-icons/fi";
import Helmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import ScrollToTop from "react-scroll-up";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      errorMessage: null,
      successMessage: null,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { password, confirmPassword } = this.state;

    if (password !== confirmPassword) {
      this.setState({ errorMessage: "Passwords do not match" });
      return;
    }

    const token = new URLSearchParams(this.props.router.location.search).get(
      "token",
    );

    if (!token) {
      this.setState({ errorMessage: "Invalid or missing token" });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_API_URL}/api/auth/reset-password`,
        {
          password,
          token,
        },
      );

      const { status, message } = response.data;

      if (status === "success") {
        this.setState({
          successMessage: "Your password has been reset successfully.",
          errorMessage: null,
        });
        setTimeout(() => {
          this.props.router.navigate("/login", { replace: true });
        }, 2500);
      } else if (status === "already_used") {
        this.setState({
          successMessage: null,
          errorMessage:
            "This token has already been used. Please request a new password reset.",
        });
      } else if (status === "expired") {
        this.setState({
          successMessage: null,
          errorMessage:
            "This token is expired or invalid. Please request a new password reset.",
        });
      }
    } catch (error) {
      this.setState({
        successMessage: null,
        errorMessage: "An error occurred. Please try again.",
      });
    }
  };
  render() {
    return (
      <Fragment>
        <Helmet pageTitle="Reset Password" />
        <Header />

        <div
          className="reset-password-area d-flex justify-content-center align-items-center"
          style={{ height: "100vh", backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="reset-password-form-wrapper text-center p-5 bg-white shadow-sm rounded">
                  <h2 className="title mb-4">Reset Password</h2>
                  <p className="mb-4">
                    Enter your new password below to reset your account
                    password.
                  </p>
                  {this.state.successMessage && (
                    <div className="alert alert-success" role="alert">
                      {this.state.successMessage}
                    </div>
                  )}
                  {this.state.errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.errorMessage}
                    </div>
                  )}
                  <form onSubmit={this.handleSubmit}>
                    <div className="input-box mb-4">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        placeholder="New Password"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="input-box mb-4">
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm Password"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="reset-password-btn">
                      <button
                        className="rn-button-style--2 btn-solid w-100"
                        type="submit"
                      >
                        <FiLock /> Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo />

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(ResetPassword);
