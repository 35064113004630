import React from "react";
import Fairytale from "./Fairytale";

import about from "../../assets/images/about/frog-prince.png";
import mp3 from "../../assets/audio/stories/frog-prince/frog-prince.mp3";

const sliderData = [
  {
    textPosition: "text-left",
    category: "Fairy Tale Hero",
    title:
      "Hello, I’m <span>The Frog Prince</span>. Join me on my Royal Quest.",
    description:
      "Follow the story of the Frog Prince, a prince turned into a frog who must find a way to break the spell and reclaim his life.",
    buttonText: "Hear My Story",
    buttonLink: "#",
    bgImageClass: "bg_image--frog-prince",
  },
];

const frogPrinceTabsData = [
  {
    title: "Story",
    content: `
      <p>The Frog Prince is a fairy tale about a princess who reluctantly befriends a frog that magically transforms into a handsome prince. The frog helps the princess retrieve a lost golden ball, and in return, she promises to let him live with her. Although initially repulsed, she eventually accepts the frog, and her acceptance breaks the spell, revealing his true form as a prince.</p>
    `,
  },
  {
    title: "Fun Facts",
    content: `
      <div>
        <p> - The Frog Prince is one of the earliest tales collected by the Brothers Grimm. </p>
        <p> - The tale has been adapted into various forms, including books, movies, and plays. </p>
        <p> - Different versions of the story exist, with some variations in how the frog transforms back into a prince. </p>
        <p> - The story explores themes of loyalty, friendship, and the importance of keeping promises. </p>
        <p> - In some versions, the transformation occurs when the princess kisses the frog, while in others, it happens when she throws him against a wall. </p>
      </div>
    `,
  },
  {
    title: "History",
    content: `
      <p>The Frog Prince is a German fairy tale that was first recorded by the Brothers Grimm in their collection "Grimm's Fairy Tales," published in 1812. The tale has its roots in earlier folklore and has been told in various versions throughout Europe. The story has been widely interpreted and adapted, becoming a staple of fairy tale literature.</p>
    `,
  },
  {
    title: "Author",
    content: `
      <p>The Brothers Grimm, Jacob (1785-1863) and Wilhelm (1786-1859), were German scholars who collected and published folklore. Their collection "Grimm's Fairy Tales" includes many famous stories such as "The Frog Prince," "Snow White," "Hansel and Gretel," and "Rapunzel." Their work has had a profound impact on the preservation and popularization of fairy tales.</p>
    `,
  },
];

const FrogPrince = () => (
  <Fairytale
    pageTitle="The Frog Prince"
    logo="dark"
    sliderData={sliderData}
    aboutImage={about}
    aboutTitle="About Fairy Tale"
    aboutDescription="The Frog Prince, once a handsome prince, is turned into a frog by a wicked witch. He must gain the trust and love of a princess to break the spell and return to his human form."
    audioSrc={mp3}
    tabsData={frogPrinceTabsData}
  />
);

export default FrogPrince;
